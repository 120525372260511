import { NlpModel } from '@shared/interfaces';

export const SALES_EMAIL = 'sales@datastand.net';

export const SUPPORT_EMAIL = 'support@datastand.net';

// Generated by:
// $ curl -X 'GET' \
//   'http://localhost:5757/annotate/models' \
//   -H 'accept: application/json'
export const ANNOTATE_MODELS: { [key: string]: NlpModel } = {
  openai: {
    id: 'openai',
    language: 'en',
    name: 'LLM',
    description: 'General Large Language Model'
  }
};

// Generated by:
// $ curl -X 'GET' \
//    'http://localhost:5757/annotate-similar-spans/models' \
//    -H 'accept: application/json'
export const ANNOTATE_SIMILAR_SPANS_MODELS: { [key: string]: NlpModel } = {
  'exact-match': {
    id: 'exact-match',
    language: 'en',
    name: 'Exact match',
    description: 'Annotate spans that are exactly the same'
  }
};

export const USERNAME_PATTERN = /^[a-z0-9_]+$/;

export const DEFAULT_LABEL_COLOR = '#9c27b0';

// Extracted from ngx-colors:
// https://github.com/KroneCorylus/ngx-colors/blob/c28bfe40fa9f6d29106e03d6cdb9ef5c693f8dc3/projects/ngx-colors/src/lib/helpers/default-colors.ts
export const LABEL_PRIMARY_COLORS = [
  '#E57373',
  '#F06292',
  '#BA68C8',
  '#9575CD',
  '#7986CB',
  '#64B5F6',
  '#4FC3F7',
  '#4DD0E1',
  '#4DB6AC',
  '#81C784',
  '#AED581',
  '#DCE775',
  '#FFF176',
  '#FFD54F',
  '#FFB74D',
  '#FF8A65',
  '#A1887F',
  '#E0E0E0',
  '#90A4AE'
];

// This is used for the API to set the limit to a very large number.
export const VERY_LARGE_NUMBER = 1_000_000;

export const PAGE_TITLE_SUFFIX = ' | Data Stand';

export const EMPTY_FIELD_VALUE = '–';
